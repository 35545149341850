import React from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import { SupportSection } from '../../components/sections';
import { CountryCode } from '../../utils/constants';

const SupportPage: React.FC = () => (
  <Layout hideHeader countryCode={CountryCode.SG}>
    <SEO title="Support" />
    <SupportSection />
  </Layout>
);

export default SupportPage;
